<template>
  <div class="login-box">
    <div class="login-content">
      <a-tabs class="page-tabs">
        <a-tab-pane key="1" tab="登录">
          <a-form
            class="login-form"
            ref="loginForm"
            layout="vertical"
            labelAlign="left"
            :model="loginForm"
            :rules="loginRules"
          >
            <a-form-item label="用户名" name="username">
              <a-input
                v-model:value="loginForm.username"
                placeholder="请输入手机号"
                :maxlength="11"
              ></a-input>
            </a-form-item>
            <a-form-item label="密码" name="password">
              <a-input
                class="login-form-password"
                v-model:value="loginForm.password"
                placeholder="请输入密码"
                autocomplete="off"
                type="password"
                @keyup.enter="onSubmit"
              ></a-input>
            </a-form-item>
            <a-form-item class="login-form-btn">
              <a-button class="login-btn" @click="onSubmit">登录</a-button>
            </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="3" tab="注册">
          <a-form
            class="register-form"
            ref="registerFormRef"
            labelAlign="left"
            :model="registerForm"
            :rules="registerRules"
            :labelCol="{ span: 7 }"
            :colon="false"
          >
            <a-form-item label="用户名" name="name">
              <a-input
                v-model:value="registerForm.name"
                placeholder="请输入您的用户名"
                :maxlength="12"
              ></a-input>
            </a-form-item>
            <a-form-item label="邮箱" name="email">
              <a-input
                v-model:value="registerForm.email"
                placeholder="请输入您的电子邮箱"
              ></a-input>
            </a-form-item>
            <a-form-item label="手机号" name="phone">
              <a-input
                v-model:value="registerForm.phone"
                placeholder="请输入您的手机号"
                :maxlength="11"
              ></a-input>
            </a-form-item>
            <a-form-item label="密码" name="password">
              <a-input
                class="login-form-password"
                v-model:value="registerForm.password"
                placeholder="请输入密码"
                autocomplete="off"
                type="password"
                @keyup.enter="onSubmit"
              ></a-input>
            </a-form-item>
            <a-form-item label="再次输入密码" name="checkPass">
              <a-input
                class="login-form-password"
                v-model:value="registerForm.checkPass"
                placeholder="请再次输入密码"
                autocomplete="off"
                type="password"
                @keyup.enter="onSubmit"
              ></a-input>
            </a-form-item>
            <a-form-item class="login-form-btn">
              <a-button class="login-btn" @click="onSubmitRegister"
                >注册</a-button
              >
            </a-form-item>
          </a-form>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-24 14:41:28
 *@version: V1.0.0
 *@description: 登录 / 注册
 *************************************************************************/
import { message } from "ant-design-vue";
export function validEmail(email) {
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  reg.lastIndex = 0;
  return reg.test(email);
}
export function validMobile(mobile) {
  const reg = /^1(3|4|5|6|7|8|9)[0-9]{9}$/;
  return reg.test(mobile);
}
export default {
  name: "Register",
  components: {},
  props: {},
  data() {
    const validateMobilePhone = async (rule, value) => {
      if (value !== "") {
        if (value.length < 11) {
          return Promise.reject("手机号码需不少于11位");
        } else {
          if (!validMobile(value)) {
            return Promise.reject("请输入正确手机号码");
          } else {
            return Promise.resolve();
          }
        }
      } else {
        return Promise.reject("请输入手机号");
      }
    };
    const validateEmail = async (rule, value) => {
      if (value !== "") {
        if (!validEmail(value)) {
          return Promise.reject("电子邮箱格式错误");
        } else {
          return Promise.resolve();
        }
      } else {
        return Promise.reject("请输入您的电子邮箱");
      }
    };
    const validatePassword = async (rule, value) => {
      if (value !== "") {
        if (value.length < 6) {
          return Promise.reject("密码长度不能少于6位数！");
        } else {
          return Promise.resolve();
        }
      } else {
        return Promise.reject("请输入密码");
      }
    };
    const validatePass2 = async (rule, value) => {
      if (value === "") {
        return Promise.reject("请您再次输入密码");
      } else if (value !== this.registerForm.password) {
        return Promise.reject("两次填写的密码不一致");
      } else {
        return Promise.resolve();
      }
    };
    return {
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          {
            required: true,
            validator: validateMobilePhone,
            trigger: "blur",
            blur: "change",
          },
        ],
        password: [
          {
            required: true,
            validator: validatePassword,
            min: 6,
            trigger: "blur",
            blur: "submit",
          },
        ],
      },
      registerForm: {
        name: "",
        email: "",
        phone: "",
        password: "",
        checkPass: "",
      },
      registerRules: {
        name: [
          {
            required: true,
            trigger: "blur",
            blur: "change",
            message: "请输入您的真实姓名",
          },
        ],
        email: [
          {
            required: true,
            trigger: "blur",
            blur: "change",
            validator: validateEmail,
          },
        ],
        phone: [
          {
            required: true,
            validator: validateMobilePhone,
            trigger: "blur",
            blur: "change",
          },
        ],
        password: [
          {
            required: true,
            validator: validatePassword,
            min: 6,
            trigger: "blur",
            blur: "submit",
          },
        ],
        checkPass: [{ validator: validatePass2, trigger: "change" }],
      },
    };
  },

  computed: {},
  watch: {},

  methods: {
    // 登录
    onSubmit() {
      let accountSet = [{ username: "18210387572", password: "123456" }];
      this.$refs.loginForm.validate().then(() => {
        const { username } = this.loginForm;
        localStorage.setItem("rmkScssUserName", username);
        const homePage = this.$router.resolve({
          path: "/",
        });
        window.open(homePage.href, "_self");
        this.$refs.loginForm.resetFields();
      });
    },
    onSubmitRegister() {
      this.$refs.registerFormRef.validate().then(() => {
        message.success("注册成功");
        this.$refs.registerFormRef.resetFields();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@inputHeight: 36px;
.login-box {
  height: 750px;
  padding-top: 140px;
  background: url("../assets/images/login_bg.png") no-repeat center/cover;
}
.login-content {
  position: relative;
  margin: auto;
  width: 836px;
  height: 440px;
  max-width: 1550px;
  box-shadow: 5px 0px 30px 0px rgba(55, 52, 169, 0.1);
  border-radius: 32px;
  background: url("../assets/images/login_bg02.png") no-repeat center/cover;
}
.page-tabs {
  position: absolute;
  right: 0;
  top: 0;
  width: 420px;
  margin: auto;
  text-align: left;
}
.login-form {
  padding: 50px 40px;
  :deep(.ant-form-item) {
    // box-shadow: 0 10px 15px rgba(210, 210, 210, 50%);
    border-radius: 8px;

    .ant-input-affix-wrapper-focused {
      border: 2px solid #3d4566;
      background-color: #ffffff;
    }
  }
}
input,
.login-form-password {
  width: 100%;
  height: @inputHeight;
  border-radius: 8px;
  padding-left: 17px;
  box-sizing: border-box;
}
.login-form-btn {
  width: 100%;
  background-color: #3d4566;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  box-shadow: 0 10px 15px rgba(135, 141, 165, 30%);
  overflow: hidden;

  .login-btn {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
    box-shadow: none;
    border-radius: 24px;
    background-color: #3d4566;
    font-size: 18px;
    color: #fff;
    letter-spacing: 4px;
  }
}
.register-form {
  padding: 5px 40px 10px;
}
:deep(.ant-form-item-explain) {
  font-size: 12px;
}
:deep(.ant-tabs-nav-container) {
  font-size: 16px;
}
:deep(.ant-tabs-nav) {
  .ant-tabs-tab-active {
    font-weight: 550;
  }
}
:deep(.ant-form-item-label) {
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
}
</style>
